/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

exports.onServiceWorkerUpdateReady = () => window.location.reload(true)

exports.onInitialClientRender = () => {
  window.addEventListener("beforeinstallprompt", e => {
    e.userChoice.then(choiceResult => {
      if (window.ga) {
        window.ga("send", "event", "Add to homescreen", choiceResult.outcome)
      }
    })
  })
  document.addEventListener("resume", () =>
    document.dispatchEvent(new Event("visibilitychange"))
  )
}
