module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-E984B35EFZ"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":true,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"],"delayOnRouteUpdate":0}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Fast Golf Scores","short_name":"Fast","start_url":"/","background_color":"#00c85c","theme_color":"#00c85c","display":"standalone","icons":[{"src":"/images/icon-192.png","sizes":"192x192","type":"image/png"},{"src":"/images/icon-512.png","sizes":"512x512","type":"image/png"},{"src":"/images/maskable_icon-192.png","sizes":"192x192","type":"image/png","purpose":"maskable"},{"src":"/images/maskable_icon-512.png","sizes":"512x512","type":"image/png","purpose":"maskable"}],"icon":"src/images/maskable_icon-1024.png","legacy":false,"include_favicon":false,"shortcuts":[{"name":"PGA Tour","short_name":"PGA Scores","description":"View PGA leaderboard","url":"/"},{"name":"European Tour","short_name":"Euro Scores","description":"View European Tour leaderboard","url":"/europeantour/"}],"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"52d2f2302eeccc63721d723138c758ed"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/","/europeantour/"],"workboxConfig":{"importWorkboxFrom":"local","dontCacheBustURLsMatching":{},"runtimeCaching":[{"urlPattern":{},"handler":"NetworkFirst"},{"urlPattern":{},"handler":"CacheFirst"},{"urlPattern":{},"handler":"NetworkFirst"},{"urlPattern":{},"handler":"StaleWhileRevalidate"}]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
