exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-europeantour-js": () => import("./../../../src/pages/europeantour.js" /* webpackChunkName: "component---src-pages-europeantour-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-presidentscup-js": () => import("./../../../src/pages/presidentscup.js" /* webpackChunkName: "component---src-pages-presidentscup-js" */),
  "component---src-pages-rydercup-js": () => import("./../../../src/pages/rydercup.js" /* webpackChunkName: "component---src-pages-rydercup-js" */),
  "component---src-pages-speed-js": () => import("./../../../src/pages/speed.js" /* webpackChunkName: "component---src-pages-speed-js" */)
}

